import Dashboard from "./dashboard";
import Subscription from "./subscription";
import Login from "./login";
import { useSelector, useDispatch } from "react-redux";
import { parseCookies } from 'nookies'
import { useEffect, useState } from "react";
import { userType } from "../components/global";
import { clearLoginData } from "../redux/actions/userAction";
import { SAVE_LOGIN } from "../redux/constants/actionTypes";

export default function Home(props) {
	const logindata = useSelector((state) => state.user);
	var islogged = logindata.islogged;   // logindata will consist true or false
	var usertype = logindata?.userdata?.userType;   // logindata will consist true or false
	var userID = logindata?.userdata?._id;
	const dispatch = useDispatch()
	var { token } = props;

	useEffect(async () => {
		if (islogged) {
			if (!logindata?.userdata?.connectedStripeId || (usertype == userType.instituteAdmin && !logindata?.userdata?.customerStripeId)) {
				let response = await fetch(`/api/auth/updateUser?userId=${userID}`, {
					method: 'GET'
				});
				if (response.status >= 200 && response.status < 300) {
					let rsp = await response.json();
					if (rsp.success) {
						activeSubscription = rsp.subscription;
						dispatch({ type: SAVE_LOGIN, data: rsp.user, token: rsp.token, subscription: rsp.subscription });
					}
				}
			}
		}
	}, []);

	useEffect(() => {
		if (!token) {
			dispatch(clearLoginData())
		}
	}, [token])

	var activeSubscription = true;
	if (islogged && usertype == userType.instituteAdmin) {
		let subscription = logindata?.isSubscribed
		activeSubscription = subscription;
	} else if (islogged && usertype == userType.teacher) {
		let subscription = logindata?.isSubscribed;
		activeSubscription = subscription;
	}

	return (
		<>
			{islogged ? activeSubscription ? (<Dashboard />) : (<Subscription />) : (<Login />)}
		</>
	);
}
export async function getServerSideProps(ctx) {
	var { token } = parseCookies(ctx);
	if (token) {
		return { props: { token } }
	} else {
		return { props: {} }
	}
}